import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';

import {
    container,
    blogPost,
    blogPostContent,
} from '../styles/blogPost.module.scss';

const BlogPost = (props) => {
    const data = useStaticQuery(graphql`
        query {
            markdownRemark {
                html
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    slug
                    title
                }
            }
        }
    `);

    return (
        <Layout>
            <div className={container}>
                <div className={blogPost}>
                    <h1>{data.markdownRemark.frontmatter.title}</h1>
                    <h2>{data.markdownRemark.frontmatter.date}</h2>
                    <div
                        className={blogPostContent}
                        dangerouslySetInnerHTML={{
                            __html: data.markdownRemark.html,
                        }}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default BlogPost;
